<template>
  <div class="page-table yetkiTanim mainDiv mb-30" id="affix-container">
    <div class="animated fadeInUp card-base baslikBg">Görev Listesi</div>

    <el-row
        :gutter="10"
        v-loading="
        loading ||
        yoneticiListLoading ||
        firmaLoading ||
        projeLoading ||
        sozlesmeLoading ||
        stateLoading
      "
        :element-loading-text="
        loading
          ? $t('src.views.apps.atama.tanimla.listeLoading')
          : yoneticiListLoading
          ? $t('src.views.apps.atama.loading.yoneticiListLoading')
          : firmaLoading
          ? $t('src.views.apps.atama.loading.firmaLoading')
          : projeLoading
          ? $t('src.views.apps.atama.loading.projeLoading')
          : sozlesmeLoading
          ? $t('src.views.apps.atama.loading.sozlesmeLoading')
          : 'Sözleşme Görevden Kaldırılıyor.'
      "
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(255, 255, 255, 1)"
    >
      <el-form
          status-icon
          :model="atamaForm"
          :rules="rulesYetki"
          ref="atamaForm"
          class="demo-ruleForm"
      >
        <el-col :lg="12" :md="12" :sm="12" :xs="12">
          <div
              class="card-base card-shadow--medium demo-box bg-white animated fadeInLeft pb-20 pt-10"
          >
            <el-row>
              <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                <el-form-item label="Personel" prop="yoneticiID"
                >:
                  <el-select
                      style="width: 100%; padding-top: 12px"
                      size="small"
                      v-model="atamaForm.yoneticiID"
                      placeholder="Personel Seçiniz"
                      @change="yoneticiSecimi"
                      filterable
                      :filter-methos="filterMethod"
                  >
                    <el-option
                        v-for="item in yoneticiler"
                        :key="item.ID"
                        :label="item.adSoyad"
                        :value="item.ID"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </div>

          <el-collapse
              class="animated fadeInUp"
              style="margin-top: 10px"
              v-model="activeCollapse"
          >
            <el-col :lg="24" :md="24" :sm="12" :xs="12">
              <el-collapse-item
                  class="card-shadow--medium"
                  :title="$t('src.views.apps.atama.tanimla.firmaAtamaTitle')"
                  name="6"
                  v-if="secim && musteriler.length !== 0"
              >
                <div class="widget">
                  <el-select
                      v-if="isDesktop"
                      style="width: 97%; text-align: center; padding-left: 10px"
                      size="small"
                      v-model="atamaForm.musteriID"
                      @change="getProjeListesi"
                      placeholder="Firma Seçiniz"
                      filterable
                      :filter-methos="filterMethod"
                  >
                    <el-option
                        v-for="item in musteriler"
                        :key="item.musteriID"
                        :label="item.musteriAdi"
                        :value="item.musteriID"
                    >
                    </el-option>
                  </el-select>

                  <el-select
                      v-if="!isDesktop"
                      style="width: 70%; text-align: center; padding-left: 20px"
                      size="small"
                      v-model="atamaForm.musteriID"
                      @change="getProjeListesi"
                      placeholder="Firma Seçiniz"
                      filterable
                      :filter-methos="filterMethod"
                  >
                    <el-option
                        v-for="item in musteriler"
                        :key="item.musteriID"
                        :label="item.musteriAdi"
                        :value="item.musteriID"
                    >
                    </el-option>
                  </el-select>
                </div>
              </el-collapse-item>
            </el-col>

            <el-col :lg="24" :md="24" :sm="12" :xs="12">
              <el-collapse-item
                  class="card-shadow--medium pt-10"
                  :title="$t('src.views.apps.atama.tanimla.projeAtamaTitle')"
                  name="7"
                  v-if="musteriSecim && musteriler.length !== 0"
              >
                <div class="widget">
                  <el-select
                      v-if="isDesktop"
                      style="width: 97%; text-align: center; padding-left: 10px"
                      size="small"
                      v-model="atamaForm.projeID"
                      @change="getSozlesmeListesi2"
                      placeholder="Proje Seçiniz"
                      filterable
                      :filter-methos="filterMethod"
                  >
                    <el-option
                        v-for="item in projeler"
                        :key="item.projeID"
                        :label="item.baslik"
                        :value="item.projeID"
                    >
                    </el-option>
                  </el-select>
                  <el-select
                      v-if="!isDesktop"
                      style="width: 70%; text-align: center; padding-left: 20px"
                      size="small"
                      v-model="atamaForm.projeID"
                      @change="getSozlesmeListesi2"
                      placeholder="Proje Seçiniz"
                      filterable
                      :filter-methos="filterMethod"
                  >
                    <el-option
                        v-for="item in projeler"
                        :key="item.projeID"
                        :label="item.baslik"
                        :value="item.projeID"
                    >
                    </el-option>
                  </el-select>
                </div>
              </el-collapse-item>
            </el-col>

            <el-col :lg="24" :md="24" :sm="12" :xs="12">
              <el-collapse-item
                  class="card-shadow--medium pt-10"
                  name="8"
                  title="Görev Atama Yap"
                  v-if="projeSecim"
              >
                <el-row>
                  <el-button
                      size="mini"
                      v-on:click="atamaYap('atamaForm', atamaForm.yoneticiID)"
                      class="onayBtn"
                      type="primary"
                  >
                    <label>Kaydet</label>
                  </el-button>
                  &nbsp;
                  <el-popover
                      placement="bottom"
                      width="320"
                      v-model="visibleSil"
                  >
                    <p>{{ $t("src.views.apps.messages.clearFormAlert") }}</p>
                    <div style="text-align: right; margin: 0">
                      <el-button
                          size="mini"
                          type="primary"
                          @click="resetForm('atamaForm')"
                      >
                        {{ $t("src.views.apps.genel.yes") }}
                      </el-button>
                      <el-button size="mini" @click="visibleSil = false">
                        {{ $t("src.views.apps.genel.no") }}
                      </el-button>
                    </div>
                    <el-button size="mini" slot="reference">
                      {{ $t("src.views.apps.genel.temizle") }}
                    </el-button>
                  </el-popover>
                </el-row>
              </el-collapse-item>
            </el-col>
          </el-collapse>
        </el-col>

        <el-col :lg="12" :md="12" :sm="12" :xs="12">
          <el-collapse
              class="animated fadeInUp"
              v-model="activeCollapse"
          >
            <el-collapse-item
                class="card-shadow--medium"
                :title="$t('src.views.apps.atama.tanimla.gorevlerTitle')"
                name="6"
                v-if="filterGorevler !== ''"
            >
              <el-tree
                  style="width: 100%; min-height: 530px; max-height: 530px"
                  class="baskaTree scrollable scroll-x"
                  node-key="sozlesmeID"
                  :data="treeData"
                  @check="handleYoneticiNodeClick"
                  icon-class
                  :show-checkbox="false"
                  ref="sozlesmeTree"
                  :props="sozlesmeTreeProps"
                  default-expand-all
                  :check-strictly="true"
              >
                <template v-slot="{ node }">
                  <div
                      style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      width: 95%;
                    "
                  >
                    {{ node.label }}
                    <el-button
                        @click="removeNode(node)"
                        type="text"
                        style="color: crimson; margin-left: 100px !important"
                        size="mini"
                        icon="el-icon-delete"
                        circle
                        v-if="node.level !== 1"
                    ></el-button>
                  </div>
                </template>
              </el-tree>
            </el-collapse-item>
          </el-collapse>
        </el-col>
      </el-form>
    </el-row>
  </div>
</template>
<script>
import ClassicEditor from "../../../../node_modules/ckeditor5-build-classic";
import musteriService from "@/WSProvider/MusteriService";
import notification from "@/notification";
import yoneticiService from "@/WSProvider/YoneticiService";
import projeService from "@/WSProvider/ProjeService";

export default {
  name: "AtamaListesi",
  components: {},
  watch: {
    sozlesmeKey() {
      this.seciliSozlesmeFiltre();
      this.seciliSozlesmeler.length > 0
          ? (this.sozlesmeTarihBaslikGoster = true)
          : (this.sozlesmeTarihBaslikGoster = false);
    },
  },
  created() {
    this.checkDevice();
  },

  mounted() {
    window.addEventListener("resize", this.checkDevice);
    this.getMusteriListesi();
    // this.getProjeListesi();
    this.getYoneticiListesi();
    this.getYoneticilerinGorevleri();
    //this.getYetkiListesi();
    //this.getMusteriProjeBaslik();
    //this.getSozlesmeListesi();
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.checkDevice);
  },

  data() {
    return {
      secim: false,
      musteriSecim: false,
      projeSecim: false,
      sozlesmeSecim: false,
      stateLoading: false,
      isDesktop: false,
      loading: false,
      firmaLoading: false,
      projeLoading: false,
      sozlesmeLoading: false,
      yoneticiListLoading: false,
      visibleSil: false,
      disabled: false,
      filterGorevler: [],

      musteriler: [],
      projeler: [],
      sozlesmeler: [],
      sozlesmeKategoriler: [],
      yoneticiler: [],
      yetkiler: [],
      gorevler: [],
      treeData: [],
      musteriData: [],
      selectedMusteri: [],
      musteriProjeler: [],
      selectedFirmalar: [],
      selectedProjeler: [],
      selectedSozlesmeler: [],
      gruplanmisSozlesmeler: [],
      tempKategoriListesi: [],

      editor: ClassicEditor,
      editorConfig: {
        language: "tr",
      },
      activeCollapse: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11"],

      atamaForm: {
        musteriID: "",
        projeID: "",
        sozlesmeID: "",
        yoneticiID: "",
        atanacakFirmaProjeler: [],
        atanacakSozlesmeler: [],
        sozlesmeList: [],
      },
      selectIcon: "1",
      page: 0,
      postPage: 0,
      sizes: 10,

      rulesYetki: {
        yoneticiAdi: [
          {
            required: true,
            message: "Lütfen Yönetici Seçiniz",
            trigger: "blur",
          },
        ],
      },

      sozlesmeTreeProps: {
        label: (data, node) => {
          return node.level === 1 ? data.sozlesmeKategoriAdi : data.sozlesmeBaslik;
        },
        children: "sozlesmeler",
      },
    };
  },
  methods: {
    /*
            isSozlesmeNode(node) {
            if(node.data.sozlesmeKategoriAdi != "BG Proje" && node.data.sozlesmeKategoriAdi != "BG İnşaat" && node.data.sozlesmeKategoriAdi != "BG Prefabrik" && node.data.sozlesmeKategoriAdi != "BG Çelik" && node.data.sozlesmeKategoriAdi != "BG Panel" && node.data.sozlesmeKategoriAdi != "BG Lojistik" && node.data.sozlesmeKategoriAdi != "BG Enerji")
                return node.childNodes == undefined || node.childNodes == null || node.childNodes.length == 0;
            },*/

    removeNode(node) {
      console.log("node");
      console.log(node);
      const gorevAtamaID = node.data.sozlesmeID;
      this.$confirm(
          "Görevli olduğunuz sözleşmeyi silmek istediğinize emin misiniz?",
          this.$t("src.views.apps.genel.uyari"),
          {
            confirmButtonText: this.$t("src.views.apps.genel.yes"),
            cancelButtonText: this.$t("src.views.apps.genel.no"),
            type: "warning",
          }
      ).then(() => {
        try {
          this.stateLoading = true;
          yoneticiService
              .gorevAtamaSil(gorevAtamaID)
              .then((response) => {
                if (response.status === 200) {
                  this.refreshPage();
                }
                this.stateLoading = false;
                notification.Status("success", this, response.msg);
                localStorage.setItem("userDataBGSurec", response.token);
              })
              .catch((err) => {
                if (err.responseJSON) {
                  let error = err.responseJSON;
                  if (error.status == 401) {
                    notification.Status(150, this);
                  } else notification.Status("errorMsg", this, error.errMsg);
                } else {
                  //notification.Status(503, this)
                }
                this.stateLoading = false;
              });
        } catch (e) {
          // notification.Status("success", this, "An error occurred while fetching the customer number.");
          this.stateLoading = false;
        }
      });
    },

    getYoneticilerinGorevleri() {
      try {
        this.yetkiListLoading = true;
        yoneticiService
            .yoneticiGorevListele2()
            .then((response) => {
              localStorage.setItem("userDataBGSurec", response.token);
              if (response.status == 200) {
                this.gorevler = response.data;
              }
              this.yetkiListLoading = false;
            })
            .catch((err) => {
              if (err.responseJSON) {
                let error = err.responseJSON;
                if (error.status == 401) {
                  notification.Status(150, this);
                } else if (error.status == 404) {
                  this.gorevler = [];
                } else notification.Status("errorMsg", this, error.errMsg);
              } else {
              }
              this.yetkiListLoading = false;
            });
      } catch (e) {
        this.yetkiListLoading = false;
      }
    },

    getYoneticiListesi() {
      try {
        this.yoneticiListLoading = true;
        yoneticiService
            .yoneticiListe(this.selectIcon, this.postPage, this.sizes, 2)
            .then((response) => {
              localStorage.setItem("userDataBGSurec", response.token);
              if (response.status === 200) {
                this.yoneticiler = response.data;
              }
              this.yoneticiListLoading = false;
            })
            .catch((err) => {
              if (err.responseJSON) {
                let error = err.responseJSON;
                if (error.status === 401) {
                  notification.Status(150, this);
                } else if (error.status === 404) {
                  this.yoneticiler = [];
                } else notification.Status("errorMsg", this, error.errMsg);
              } else {
              }
              this.yoneticiListLoading = false;
            });
      } catch (e) {
        this.yoneticiListLoading = false;
      }
    },

    yoneticiSecimi() {
      this.secim = true;
      this.atamaForm.musteriID = "";
      this.musteriSecim = false;
      this.projeSecim = false;
      if (this.gorevler.length === 0) {
        return
      }
      this.filterGorevler = this.gorevler.find(
          (gorev) => gorev.yoneticiID === this.atamaForm.yoneticiID
      );

      if (this.filterGorevler === undefined) {
        notification.Status(
            "warning",
            this,
            "Seçtiğiniz personele ait görevli firma bulunmamaktadır."
        );
        this.secim = false;
        return
      }
      console.log("filterGorevler", this.filterGorevler);
      this.musteriData = this.filterGorevler;
      const musteriler = Object.values(this.musteriData.musteriler);
      this.musteriData.musteriler = musteriler;

      this.musteriler = musteriler.map((musteri) => {
        return {
          musteriAdi: musteri.musteriBaslik,
          musteriID: musteri.musteriID,
        };
      });

      if (this.musteriler.length === 0) {
        notification.Status(
            "warning",
            this,
            "Seçtiğiniz personele ait görevli firma bulunmamaktadır."
        );
      }


    },

    getProjeListesi() {
      this.musteriSecim = true;
      this.projeSecim = false;
      this.atamaForm.projeID = "";
      const selectedMusteriID = this.atamaForm.musteriID;
      const selectedMusteri = this.musteriData.musteriler.find(
          (musteri) => musteri.musteriID === selectedMusteriID
      );
      const projeList = Object.values(selectedMusteri.projeler).map((proje) => {
        return {
          projeID: proje.projeID,
          baslik: proje.projeBaslik,
          sozlesmeKategoriler: proje.sozlesmeKategoriler,
        };
      });
      this.projeler = selectedMusteri ? projeList : [];
      this.selectedMusteri = selectedMusteri;
    },

    getSozlesmeListesi2() {
      try {
        this.sozlesmeLoading = true;
        this.projeSecim = true;
        const selectedProjeID = this.atamaForm.projeID;
        const projeler = Object.values(this.selectedMusteri.projeler);
        const selectedProje = projeler.find(
            (proje) => parseInt(proje.projeID) === parseInt(selectedProjeID)
        );
        this.sozlesmeKategoriler = selectedProje
            ? selectedProje.sozlesmeKategoriler
            : [];

        console.log("selectedProje", selectedProje);
        const sozlesmeKategoriler = Object.values(selectedProje.sozlesmeKategoriler);
        const treeData = [];
        for (let item of sozlesmeKategoriler) {
          if (treeData.some(el => el.sozlesmeKategoriID === item.sozlesmeKategoriID)) {
            const index = treeData.findIndex(el => el.sozlesmeKategoriID === item.sozlesmeKategoriID);
            treeData[index].sozlesmeler.push({
              sozlesmeID: item.sozlesmeID,
              sozlesmeBaslik: item.sozlesmeBaslik
            });
          } else {
            treeData.push({
              sozlesmeKategoriID: item.sozlesmeKategoriID,
              sozlesmeKategoriAdi: item.sozlesmeKategoriAdi,
              sozlesmeler: [{
                sozlesmeID: item.sozlesmeID,
                sozlesmeBaslik: item.sozlesmeBaslik
              }]
            });
          }
        }

        console.log("treeData=>", treeData)
        this.treeData = treeData;
        const tumSozlesmeler = [];
        projeService
            .personelProjeSozlesmeleri(this.atamaForm.projeID)
            .then((response) => {
              localStorage.setItem("userDataBGSurec", response.token);
              if (response.count != 0) {
                this.sozlesmeler = response.data;
                console.log("sozlesmeler", this.sozlesmeler);

                this.sozlesmeler.forEach((sozlesme) => {
                  tumSozlesmeler.push(...sozlesme.sozlesmeler);
                });

                if (tumSozlesmeler.length == 0) {
                  notification.Status(
                      "warning",
                      this,
                      "Bu projeye ait görevli sözleşme bulunmamaktadır."
                  );
                  this.projeSecim = false;
                }
              }
              this.sozlesmeLoading = false;
            })
            .catch((err) => {
              if (err.responseJSON) {
                let error = err.responseJSON;
                if (error.status == 401) {
                  notification.Status(150, this);
                } else if (error.status == 404) {
                  this.sozlesmeler = [];
                } else if (error.status == 503) {
                  this.sozlesmeler = [];
                  notification.Status(
                      "warning",
                      this,
                      "Bu projenin sözleşmesi bulunmamaktadır."
                  );
                } else notification.Status("errorMsg", this, error.errMsg);
              } else {
                //notification.Status(503, this)
              }
              this.sozlesmeLoading = false;
            });
      } catch (e) {
        // notification.Status("success", this, "An error occurred while fetching the customer list.")
        this.sozlesmeLoading = false;
      }
    },

    atama(yetkiKodu, secili) {
      if (secili == 1) {
        this.atamaForm.atanacakYetkiler.push(yetkiKodu);
      } else {
        const index = this.atamaForm.atanacakYetkiler.findIndex(
            (item) => item === yetkiKodu
        );
        if (index !== -1) {
          this.atamaForm.atanacakYetkiler.splice(index, 1);
        }
      }
    },

    getMusteriListesi() {
      try {
        this.firmaLoading = true;
        musteriService
            .musteriListesi(this.selectIcon, "", "")
            .then((response) => {
              localStorage.setItem("userDataBGSurec", response.token);
              if (response.status == 200) {
                this.musteriler = response.data;
              }
              this.firmaLoading = false;
            })
            .catch((err) => {
              if (err.responseJSON) {
                let error = err.responseJSON;
                if (error.status == 401) {
                  notification.Status(150, this);
                } else if (error.status == 404) {
                  this.musteriler = [];
                } else notification.Status("errorMsg", this, error.errMsg);
              } else {
                //notification.Status(503, this)
              }
              this.firmaLoading = false;
            });
      } catch (e) {
        // notification.Status("success", this, "An error occurred while fetching the customer list.")
        this.firmaLoading = false;
      }
    },

    getMusteriProjeBaslik() {
      try {
        this.firmaLoading = true;
        musteriService
            .musteriProjeBaslik()
            .then((response) => {
              localStorage.setItem("userDataBGSurec", response.token);
              if (response.status == 200) {
                this.musteriProjeler = response.data;
              }
              this.firmaLoading = false;
            })
            .catch((err) => {
              if (err.responseJSON) {
                let error = err.responseJSON;
                if (error.status == 401) {
                  notification.Status(150, this);
                } else if (error.status == 404) {
                  this.musteriProjeler = [];
                } else notification.Status("errorMsg", this, error.errMsg);
              } else {
                //notification.Status(503, this)
              }
              this.firmaLoading = false;
            });
      } catch (e) {
        // notification.Status("success", this, "An error occurred while fetching the customer list.")
        this.firmaLoading = false;
      }
    },

    handleYoneticiNodeClick(checkedNodes, checkedKeys, halfCheckNodes) {
      this.atamaForm.sozlesmeList = checkedKeys.checkedNodes;
      this.tempKategoriListesi = [];
      checkedKeys.checkedNodes.forEach((el) =>
          this.tempKategoriListesi.push(el.sozlesmeID)
      );
      // this.selectedSozlesmeler = this.$refs.yoneticiTree.getCheckedKeys();
    },

    atamaYap(formName) {
      this.$refs[formName].validate((valid, errors) => {
        if (valid) {
          this.$confirm(
              "Görev atamak istediğinize emin misiniz?",
              this.$t("src.views.apps.genel.uyari"),
              {
                confirmButtonText: this.$t("src.views.apps.genel.yes"),
                cancelButtonText: this.$t("src.views.apps.genel.no"),
                type: "warning",
              }
          )
              .then(() => {
                this.loading = true;
                if (this.atamaForm.yoneticiID == "") {
                  notification.Status("warning", this, "Lütfen Personel Seçiniz");
                  this.loading = false;
                  return;
                }

                yoneticiService
                    .gorevAtama(
                        this.atamaForm.yoneticiID,
                        this.atamaForm.musteriID,
                        this.atamaForm.projeID,
                        this.tempKategoriListesi
                    )
                    .then((response) => {
                      if (response.status == 200) {
                        localStorage.setItem("userDataBGSurec", response.token);
                        notification.Status(
                            "success",
                            this,
                            "Görev Ataması Başarı ile Tamamlandı"
                        );
                        this.resetForm(formName);
                        // EventBus.$emit("yetkiList", true)
                        //functions.sayfaKapat("yetkitanimla", this);
                      }

                      if (response.status == 202) {
                        notification.Status(
                            "errorMsg",
                            this,
                            "Bu Sözleşme Başkasına Atanmış"
                        );
                      }

                      this.loading = false;
                    })
                    .catch((err) => {
                      if (err.responseJSON) {
                        let error = err.responseJSON;
                        if (error.status == 401) {
                          notification.Status(150, this);
                        } else notification.Status("errorMsg", this, error.errMsg);
                      } else {
                        //notification.Status(503, this)
                      }
                      this.loading = false;
                    });
              })
              .catch((e) => {
              });
        } else {
          for (var k in errors) {
            errors[k].forEach((element) => {
              notification.Status("warning", this, element.message);
            });
            this.loading = false;
          }
        }
      });
    },

    checkDevice() {
      this.isDesktop = window.innerWidth >= 821;
    },

    filterMethod(query, item) {
      return item.label.toLowerCase().indexOf(query.toLowerCase()) >= 0;
    },

    refreshPage() {
      this.page = 1;
      this.postPage = 0;
      this.loading = false;
      this.stateLoading = false;
      this.yoneticiListLoading = false;
      this.treeData = [];
      this.atamaForm = {
        musteriID: "",
        projeID: "",
        sozlesmeID: "",
        yoneticiID: "",
        atanacakFirmaProjeler: [],
        atanacakSozlesmeler: [],
        sozlesmeList: [],
      };
      this.musteriSecim = false;
      this.projeSecim = false;
      this.sozlesmeSecim = false;
      this.musteriler = [];
      this.projeler = [];
      this.sozlesmeler = [];
      this.sozlesmeKategoriler = [];
      this.yoneticiler = [];
      this.secim = false;
      this.getMusteriListesi();
      this.getYoneticiListesi();
      this.getYoneticilerinGorevleri();
    },

    indexMethod(index) {
      return (this.page - 1) * 10 + index + 1;
    },

    handleSizeChange(val) {
      this.sizes = val;
      this.getMusteriListesi();
      this.getYoneticiListesi();
    },

    handleChange(event) {
      this.selectIcon = event;
      this.page = 1;
      this.postPage = 0;
      this.getMusteriListesi();
      this.getYoneticiListesi();
      document
          .querySelector(".scrollable")
          .scrollTo({top: 0, behavior: "smooth"});
    },

    pageChange(val) {
      this.postPage = (val - 1) * this.sizes;
      this.getMusteriListesi();
      this.getYoneticiListesi();
    },

    vazgec(formName) {
      this.$refs[formName].resetFields();
      this.editDialog = false;
      this.loading = false;
      this.stateLoading = false;
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.visibleSil = false;
      this.yoneticiSecimi();
      this.secim = false;
    },
  },
};
</script>

<style lang="scss">
.sozlesmeSelect {
  .el-row {
    padding: 10px 10px 10px 8px;
  }

  .el-row:nth-child(2n) {
    background: #f3f7fa !important;
  }
}
</style>

<style>
.tree-node-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.remove-button {
  margin-left: 10px;
  justify-self: flex-end;
}
</style>
